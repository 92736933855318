import api from '@/api'
import { inject } from "vue";

export const $api = { ...api }

export function useApi() {
  return inject('$api')
}

export function useEndpoint(endpoint) {
  return inject('$api')[endpoint]
}

export function createApi(app, options) {
  app.provide('$api', $api)
  app.config.globalProperties.$api = $api
}