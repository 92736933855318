<script setup>
import {useScroll} from "@vueuse/core";
import {computed, onUnmounted, ref, toRef, toValue} from "vue";
import ChevronDown from '@icons/chevron-double--down.svg'
import Avatar from "@/app/components/Avatar.vue";
import {createChatStore} from "@/app/stores/chat";
import {useAuth} from "@/app/stores/auth";

const props = defineProps(['contextId'])

const chatStore = createChatStore(props.contextId)
const auth = useAuth()

const chatEl = ref()
const {y, arrivedState } = useScroll(chatEl)
const arrivedBottom = toRef(arrivedState, 'bottom')

chatStore.list()

const isSelf = (user) => {
  return user.email === auth.userEmail
}

onUnmounted(() => {
  chatStore.$dispose()
})

const scrollPercent = computed(() => (
    Math.abs(toValue(y))
    / (toValue(chatEl)?.scrollHeight ?? 0)
    * 100
  )
)
</script>

<template>
<div class="chat">
  <div class="chat__box">
    <ul class="message-list" v-if="chatStore.messages?.length"
        ref="chatEl">
      <li v-for="{content, user, createdAt} in chatStore.messages">
        <div class="message" :class="{'message--self': isSelf(user)}">
          <div class="message__avatar" v-if="!isSelf(user)">
            <Avatar :user="user" size="mini" class="w-12 h-12" />
          </div>
          <div class="message__main">
            <span class="user" v-if="!isSelf(user)">
              {{user.email}}
            </span>
            <span class="time">
              {{createdAt}}
            </span>
            <p class="content">
              {{ content }}
            </p>
          </div>
        </div>
      </li>
    </ul>

    <div v-else class="empty">
      <p>Crickets...</p>
    </div>

    <div v-if="!arrivedBottom && scrollPercent > 15"
         class="scrollplz">
      <button class="mx-auto p-1 block animate-bounce bg-gray-500/50 rounded-full border-2 border-gray-600/50"
              @click="y = 0">
        <ChevronDown class="fill-call-it-600 stroke-call-it-600 stroke-2" />
      </button>
    </div>
  </div>


  <form name="chat"
        class="chat__form"
        @submit.prevent="chatStore.send()"
        @keydown.enter.prevent="chatStore.send()">

    <div class="field">
      <input name='message' type="text" placeholder="Talk smack here:" v-model="chatStore.message">
    </div>

    <button class='shrink mt-2.5 btn btn--positive' @click='chatStore.send()' type="button">
      Send
    </button>
  </form>
</div>
</template>

<style scoped>

.chat {
  @apply
    flex
    flex-col
    h-full
    overflow-y-hidden
}

.chat__box {
  @apply
    relative
    overflow-hidden
    basis-[calc(100%-55px)]
    ;
}

.chat__form {
  @apply
    flex 
    flex-nowrap
    items-center
    basis-[55px]
  ;
  
  .field {
    @apply
      grow
      mr-4
    ;
  }
  input {
    @apply
      w-full;
  }
}

.scrollplz {
  @apply
    absolute inset-x-0 bottom-0 w-full
}


.message-list {
  @apply
    flex flex-col-reverse gap-4 h-full overflow-y-auto
    
}

.message {
  @apply
      flex items-start
      pr-2 pb-5

      w-[520px]
      max-w-[80%]
    ;

  &--self {
    @apply
      justify-end
      ml-auto
      text-right
      text-ci2-brand
  }
}

.message__avatar {
  @apply
    mr-6
}

.message__main {
  .user, .time {
    @apply
      text-sm;
  }

  .user {
    @apply
      font-bold mr-2
    ;
  }

  .time {
    @apply
      italic
  }
}

.empty {
  @apply
    flex
    items-center
    justify-center
    h-full;
  
  p {
    @apply
      text-xl
      italic
  }
}

</style>