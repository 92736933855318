import { ref, watch, computed } from 'vue'
import { defineStore } from "pinia";
import { useCrud } from "@/app/composable/useCrud";
import { useApi } from "@/app/services/api";
import { useAuth } from "@/app/stores/auth"

console.log(useAuth)

export const useCalls = defineStore('calls', () => {

  const api = useApi();

  const { data, meta, index, loading, error, endpoint, collection } = useCrud(api.apiV1Calls, { name: "call" });

  const eventMap = ref(new Map)

  function byEvent(eventId) {
    return eventMap.value.get(eventId)
  }
  function buildEventMap() {
    eventMap.value = new Map(
      data.value.map((c) => [c.eventId, c])
    )
  }

  watch(data, buildEventMap, { deep: true, immediate: true })

  const active = computed(() => {
    return data.value.filter((c) => {
      return ['placed', 'closed'].includes(c.state)
    })
  })



  // Custom endpoint for placing a call
  async function placeCall({ id: spotId, eventId, wager = 1 }) {
    const auth = useAuth()
    console.log('auth: ', auth)
    return await endpoint.create({
      data: {
        call: {
          spotId,
          userId: auth.userId,
          wager
        }
      }
    }, (res) => {
      console.log('placeCall', res)
      return collection.update(res.call)
    });
  }

  return {
    active,
    byEvent,
    eventMap,
    data,
    meta,
    index,
    placeCall, // Expose the custom endpoint
    loading,
    error,
  };
});
