import { defineStore } from 'pinia';
import { toRefs } from 'vue';
import { $api } from "@/app/services/api";
import { useCrud } from "~/app/composable/useCrud"

export const useTransactions = defineStore('transactions', () => {

  const { index, data, loading, error } = useCrud($api.apiV1Transactions, { name: 'transaction'})

  return { index, data, loading, error }
})