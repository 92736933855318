<script setup>
import {computed} from 'vue'
import {UserIcon} from '@heroicons/vue/24/solid';

const props = defineProps({
  user: Object,
  size: String // TODO - standardize actual sizes around this prop
})

const avatarUrl = computed(() => {
  return props.user.avatar[props.size ? props.size : 'default']
})

</script>

<template>

<div class="overflow-hidden rounded-full">
  <img v-if="user.avatar" :src="avatarUrl" :alt="`${user.email}'s avatar`" />
  <UserIcon v-else class="icon w-full h-full" />
</div>

</template>