<script setup>
import Pagination from "@/app/components/Pagination.vue";
import {useScore} from "@/app/stores/score";
import Avatar from "@/app/components/Avatar.vue";

const store = useScore()
const props = defineProps(['slug'])

</script>

<template>
  <section class="scoreboard divide-y">
    <Pagination path='/leaderboard' :params='slug' v-bind="store.pager"/>

    <template v-if="store.loading">
      Loading...
    </template>

    <template v-else>
      <div v-for="leader in store.scores"
           class="block sm:flex flex-row flex-nowrap py-4 gap-8">
        <span> #{{ leader.rank }} </span>
        <div class="grow flex flex-row items-center justify-start">
          <Avatar :user="leader.user" size="mini" class="h-6 w-6" />
          <p class="font-bold">
            {{ leader.userEmail }}
          </p>
        </div>
        <span class="w-1/4"> Wins: &nbsp; {{ leader.wins }} </span>
        <span class="w-1/4"> Loses: &nbsp; {{ leader.losses }} </span>
      </div>
    </template>

    <Pagination path='/leaderboard' v-bind="store.pager"/>
  </section>

</template>

<style scoped></style>