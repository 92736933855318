import { defineStore } from "pinia";
import { reactiveComputed, useArrayFilter } from "@vueuse/core";
import { ref, computed, toValue } from "vue";
import { $api as $apiBase } from "@/app/services/api";
import { useCollection } from "@/app/composable/useCollection";
import { useAuth } from "@/app/stores/auth";
import { useCalls } from "@/app/stores/calls";
import { sessionChannelStore } from '@/app/stores/channel';



export const useCurrentSession = defineStore('currentSession', () => {
  console.log('useCurrentSession')
  const data = ref({})
  const sessionId = computed(() => toValue(data).id)
  const sessionState = computed(() => toValue(data).state)

  const $api = $apiBase.apiV1Sessions

  const isLoading = computed(() => toValue($api.show.loading) || toValue($api.index.loading))
  const eventStore = useSessionEvents()
  const callStore = useCalls()
  const channel = sessionChannelStore()

  const eventHistory = reactiveComputed(() => {
    return eventStore.history.map((event) => {
      return {
        ...event,
        call: callStore.byEvent(event.id) || { wager: 1 }
      }
    })
  })

  const events = reactiveComputed(() => {
    return eventStore.action.map((event) => {
      return {
        ...event,
        call: callStore.byEvent(event.id) || { wager: 1 }
      }
    })
  })

  const update = ({ session }) => {
    const dataVal = toValue(data)
    data.value = {
      ...dataVal,
      ...session
    }

    if (session.events) {
      eventStore.updateAll(session.events)
    }
  }

  const updateEvent = (event) => {
    eventStore.update(event)
  }

  const subscribe = async (session) => {
    channel.subscribe(session.gid)

    channel.on('event:hide', ({ event }) => updateEvent(event))
    channel.on('event:show', ({ event }) => updateEvent(event))
    channel.on('event:publish_event', ({ event }) => updateEvent(event))
    channel.on('event:close_event', ({ event }) => updateEvent(event))
    channel.on('event:resolve', ({ event }) => updateEvent(event))

    channel.on('session:update', (session) => update(session))
    channel.on('session:start', (session) => update(session))
    channel.on('session:pause', (session) => update(session))
    channel.on('session:resume', (session) => update(session))
    channel.on('session:stop', (session) => update(session))
  }

  const unsubscribe = () => {
    channel.unsubscribe()
  }

  const setCurrent = async (slug) => {
    return await $api.show({ params: { id: slug } }, async (res) => {
      const { session } = res

      data.value = session

      await subscribe(session)
      await eventStore.index(session.id)

      return session
    })
  }



  const isRunning = computed(() => ['live', 'paused'].includes(sessionState.value))
  const isDraft = computed(() => sessionState.value === 'draft')
  const isEnded = computed(() => sessionState.value === 'ended')
  const isPaused = computed(() => sessionState.value === 'paused')

  const startSession = async () => { await $api.start({ params: { id: sessionId.value } }) }
  const pauseSession = async () => { await $api.pause({ params: { id: sessionId.value } }) }
  const resumeSession = async () => { await $api.resume({ params: { id: sessionId.value } }) }
  const stopSession = async () => { await $api.stop({ params: { id: sessionId.value } }) }

  return {
    sessionId,
    sessionState,
    setCurrent,
    subscribe,
    unsubscribe,
    data,
    events,
    eventHistory,
    channel,
    startSession,
    pauseSession,
    resumeSession,
    stopSession,
    isLoading,
    isDraft,
    isRunning,
    isEnded,
    isPaused
  }
})

export const useSessions = defineStore('sessions', () => {
  const $api = $apiBase.apiV1Sessions
  const { loading, error } = $api
  const {
    data,
    update: _update,
    find: _find,
    updateAll: _updateAll,
    findBy: _findBy
  } = useCollection({})

  const index = async () => await $api.index({}, _updateAll)

  const findOrFetch = async (id) => {
    return _find(id) ?? await $api.show({ params: { id } }, _update)
  }

  const findBySlug = (slug) => {
    return _findBy((obj) => obj.slug === slug)
  }

  const create = () => {
    console.log('create sessions', data.value)
  }

  return {
    index,
    findBySlug,
    create,
    data,
    loading,
    error,
  }
})

export const useSessionEvents = defineStore('session:events', () => {
  const { apiV1SessionsEvents } = $apiBase
  const auth = useAuth()
  const data = ref([])
  const { updateAll, update } = useCollection(data)


  // scoped computed props
  const actionStates = computed(() => auth.isAdmin ? ['open', 'draft', 'closed'] : ['open', 'closed'])
  const historyStates = computed(() => auth.isAdmin ? ['open', 'draft'] : ['open'])

  const action = useArrayFilter(data, (e) => actionStates.value.includes(e.state))
  const history = useArrayFilter(data, (e) => !historyStates.value.includes(e.state))

  const index = async (sessionId) => {
    const { events } = await apiV1SessionsEvents.index(
      {
        params: {
          sessionId
        }
      }
    )

    updateAll(events)
    return events
  }


  return {
    updateAll,
    update,
    index,
    data,
    action,
    history
  }
})