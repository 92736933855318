<script setup>
import {computed} from "vue";
import {RouterLink, useRoute} from "vue-router";

const route = useRoute()

const props = defineProps({page: Number|String})

const pageUrl = computed(() => {
  return `${route.path}?page=${props.page}`
})

</script>

<template>
<span> <router-link :to="pageUrl"><slot /></router-link> </span>
</template>
