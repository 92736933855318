<script setup>
const stripe = Stripe('pk_test_dUnQNVlXFraF0HsMnjfgd7on');

import {useApi} from "@/app/services/api";
import {ref} from "vue";

const error = ref(null)
const loading = ref(false)
const $api = useApi()

const paymentElementOptions = {
  layout: "tabs",
};

const appearance = {
  theme: 'stripe'
}

const options = [{
  cost: 10,
  points: 100
}, {
  cost: 20,
  points: 200
}, {
  cost: 100,
  points: 1000
}]

let elements

const createPaymentIntent = async (option) => {
  const { clientSecret } = await $api.apiV1Payments.create({
    data: {option}
  })

  elements = stripe.elements({ appearance, clientSecret })
  const element = elements.create('payment', paymentElementOptions)
  element.mount('#payment-element')
}

const handleSubmit = async (e) => {
  loading.value = true

  const { error: stripeError } = await stripe.confirmPayment({
    elements,
    confirmParams: {
      return_url: 'http://localhost:3000/payments'
    }
  })

  error.value = stripeError
  loading.value = false
}

const cardError = () =>  {
  return error?.value && (
      error.type === 'card_error' ||
      error.type === 'validation_error'
  )
}

</script>

<template>

{{loading}}

<div v-if="error">
  <div v-if="cardError()">
    {{error.message}}
  </div>

  <div v-else>
    An unexpected error occurred.
  </div>
</div>

<form id="payment-form"
      :class="{}"
      class="container max-w-2xl"
      @submit.prevent="handleSubmit">
  <div id="payment-element"
       :class="{hidden: loading}"></div>
    <button id="submit" :disabled="loading">
      <div class="spinner" id="spinner"></div>
      <span id="button-text">Pay now</span>
    </button>
  <div id="payment-message">
  </div>
</form>

<div>
  <div v-for="option in options">
    Cost: ${{option.cost}} <br>
    Points: {{option.points}} <br>

    <button @click="createPaymentIntent(option)"> Purchase </button>
  </div>
</div>
</template>

<style scoped>

</style>