export const initialState = () => ({
	name: undefined,
	trueOutcome: {
		label: undefined,
		odds: undefined,
		image: undefined
	},
	falseOutcome: {
		label: undefined,
		odds: undefined,
		image: undefined
	},
	result: undefined,
	duration: '60',
});

// keep data shape for new blueprintEvents consistent - would ideally get the EventForm / event model in sync
export class EventUpdate {
	constructor(eventFormData, blueprintId, position) {
		this.name = eventFormData.name;
		this.blueprintId = blueprintId;
		this.duration = eventFormData.duration;
		this.position = eventFormData.position ?? position;
		
		this.trueOutcome = eventFormData.trueOutcome;
		this.falseOutcome = eventFormData.falseOutcome;
		this.resolution = eventFormData.resolution;
		
		// todo - unclear what's necessary re: trueOutcomeLabel vs trueOutcome, etc
		// this.trueOutcomeLabel = eventFormData.trueOutcome?.label;
		// this.falseOutcomeLabel = eventFormData.falseOutcome?.label;
	}
};

export function formatEventsOrderUpdate(events, affectedPosition, eventAdded = true) {
	return events.map((x) => {
		const modifiedPosition = eventAdded ? x.position + 1 : x.position - 1;

		return {
			id: x.id,
			position: x.position < affectedPosition ? x.position : modifiedPosition,
		}
	});

}