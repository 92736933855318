// JsFromRoutes CacheKey 48090c2030251c768d9ff092940a9cc2
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper, defineEndpointHelper } from '~/app/services/apiFactory'
// api/v1/users
export default defineEndpointHelper('api/v1/users', {
me: /* #__PURE__ */ definePathHelper('get', '/api/v1/users/me'),
index: /* #__PURE__ */ definePathHelper('get', '/api/v1/users'),
show: /* #__PURE__ */ definePathHelper('get', '/api/v1/users/:id'),
update: /* #__PURE__ */ definePathHelper('patch', '/api/v1/users/:id'),
})