<script setup>
import { useAuth } from "@/app/stores/auth";
import MainHeader from "./menu/MainHeader.vue";

const props = defineProps({
  userGid: String,
  userId: Number,
  userEmail: String,
  userRoles: Array,
  csrfToken: String,
  csrfParam: String,
  userIsGuest: Boolean,
})

const auth = useAuth()

auth.signIn({ ...props })

</script>

<template>
  <div class="app">
    <div class="app__header">
      <MainHeader />
    </div>
    <div class="app__view">
      <router-view />
    </div>
  </div>
</template>

<style scoped>
.app {
  @apply h-full
}

.app__header {
  @apply w-full fixed z-10
}

.app__view {
  @apply h-full flex flex-col pt-[64px]
  /* header offset (mobile) */
}
</style>