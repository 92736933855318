<script setup>
import { ref } from "vue"
import { useRouter } from "vue-router"
import { useBlueprint } from "@/app/stores/blueprint"
import BlueprintForm from "./BlueprintForm.vue"
import Page from "@/app/components/Page.vue"

const blueprint = ref({})
const router = useRouter()
const store = useBlueprint()
// const devtools = useDevtools()

const createBlueprint = async (data) => {
  await store.create(data)

  blueprint.value = {}
  await returnTo()
}

async function cancelCreate() {
  await returnTo()
}

async function returnTo() {
  router.push("/blueprints")
  await router.isReady()
}


</script>

<template>
  <Page>
    <template #title>New Blueprint</template>
    <BlueprintForm v-model="blueprint" @update:modelValue="createBlueprint" @cancel="cancelCreate()" />
  </Page>
</template>