<script setup>
import {computed, reactive, ref} from "vue";
import CheckIcon from '@icons/confirm-circle.svg?component';
import XIcon from '@icons/cancel-circle.svg?component';

const props = defineProps([
  'id',
  'name',
  'label',
  'odds',
  'value',

  'result',
  'call',
  'state',
  'createdAt',
  'updatedAt',
  'image', // TODO - what's this actually doing? seems like imageThumb is what's rendered below
  'imageThumb',
]);

const emit = defineEmits(['spot:click'])
const disabled = computed(() => props.state === 'closed')
const isCalled = computed(() => props.id === props.call?.spotId)
const isResult = computed(() => props.value === props.result)
const isWon = computed(() => isCalled.value && isResult.value)
const isClosed = computed(() => props.state === 'closed');

const rootEl = ref(null)

function confirmCall() {
  // console.log(props, rootEl);
  emit('spot:click', props, rootEl)
}

const classes = reactive({
  'spot--open': props.state === 'open',
  'spot--closed': isClosed,
  'spot--called': isCalled,
  'spot--result': isResult,
  'spot--won': isWon,
  'spot--true': props.value,
  'spot--false': !props.value,
})

const domId = computed(() => {
  return `spot-${props.id}`
})
</script>

<template>
  <div :id="domId" class="spot relative" ref="rootEl">
    <!--  Indicator-->
    <span v-if="isClosed && isCalled" class="indicator" :class="`${isWon ? 'winner': 'loser'}`">
      <CheckIcon  v-if="isWon"/>
      <XIcon v-if="!isWon"/>
    </span>

    <button class="spot__button"
            :id="id"
            :disabled="disabled"
            :class="classes"
            @click="confirmCall">

      <span :for="id" class="label">
        {{ label }}
      </span>

      <img v-if="imageThumb"
           :src="imageThumb"
           alt="spot thumbnail" />
    </button>

  </div>
</template>

<style scoped>
  .spot {
    &--open {
      &, * {
        @apply cursor-pointer;
      }
    }

    &--closed {
      &, * {
        @apply cursor-not-allowed;
      }
    }

    &--result {
      &::before {
        content: "result";
        
        @apply
          absolute
          top-[-1px] 
          right-[-1px] left-[-1px]
          bg-ci2-white
          text-ci2-black
          text-sm font-bold
          px-1
          ;
      }
    }

    &--called {
      @apply bg-ci2-brand;
    }

    &--won {

    }

    &--true {

    }

    &--false {

    }
  }

  .spot__button {
    @apply
      relative

      flex flex-col
      items-center justify-center

      px-4 py-6
      border-4 border-ci2-white rounded-xl
      ;

    .label {
      @apply
        font-bold text-2xl
    }
  }

  .indicator {
    @apply
      z-10 absolute -left-3 -top-3 overflow-hidden;

    svg {
      @apply
        w-6 h-6 md:w-8 md:h-8 stroke-2 opacity-100 fill-ci2-black
      ;
    }

    &.winner svg {
      @apply
        stroke-ci2-brand
    }

    &.loser svg {
      @apply 
        stroke-utility-error
    }
  }
</style>
