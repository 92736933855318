<script setup>

import { onMounted } from 'vue';
import AdminLink from "@/app/components/admin/AdminLink.vue";
import { useBlueprint } from '@/app/stores/blueprint';
import Page from '@/app/components/Page.vue';
import { useAuth } from '@/app/stores/auth';
import { storeToRefs } from 'pinia';

const blueprintStore = useBlueprint();
const auth = useAuth();

const { blueprints } = storeToRefs(blueprintStore);

onMounted( async () => await blueprintStore.index() );

</script>

<template>
	<Page>
		<template #title>Blueprints</template>
		<template #actions v-if="auth.isAdmin">
			<admin-link to="/blueprints/new" class="btn btn--positive">Add New</admin-link>
		</template>

		<div class="blueprints-index">
			<div class="blueprint" v-for="b in blueprints">
				<admin-link :to="`/blueprints/${b.id}`">
					<h2 class="text-xl">{{ b.name }}</h2>
					<div class="info">
						<p><span>Created:</span> {{ b.createdAt }}</p>
						<p><span>Last Updated:</span> {{ b.updatedAt }}</p>
					</div>
				</admin-link>
			</div>
		</div>
	</Page>
	
</template>

<style scoped>

.blueprints-index {
	@apply
		w-full
}

.blueprint {
	@apply
		w-full p-4 mt-4

		border-2 border-ci-tertiary
		bg-ci-secondary
		rounded

		hover:no-underline
	;

	.info {
		@apply
			mt-4
			text-xs
		;
		span {
			@apply
				text-ci2-grey2
		}
	}
}

</style>