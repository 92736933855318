import BlueprintIndex from './BlueprintIndex.vue';
import BlueprintShow from './BlueprintShow.vue';
import BlueprintEdit from './BlueprintEdit.vue';
import BlueprintCreate from "./BlueprintCreate.vue";


export const route = {
	path: '/blueprints',
	children: [
		{
			path: '',
			name: 'blueprints',
			component: BlueprintIndex,
			meta: { requiresAuth: true }
		},
		{
			path: ':id',
			component: BlueprintShow,
			meta: { requiresAuth: true }
		},
		{
			path: ':id/edit',
			component: BlueprintEdit,
			meta: { requiresAuth: true }
		},
		{
			path: 'new',
			component: BlueprintCreate,
			meta: { requiresAuth: true }
		}
	]
};