<script setup>

import {useAuth} from "@/app/stores/auth";
import {useFileDialog} from "@vueuse/core";
import {useUpload} from "@/app/services/useUpload";
import {useApi} from "@/app/services/api";
import {storeToRefs} from "pinia";
import {onMounted, watch} from "vue";
import PencilIcon from '@icons/pencil--solid.svg?component';
import CoinsIcon from '@icons/coins.svg?component';
import {useRoute} from "vue-router";
import {useCurrentUser} from "~/app/stores/currentUser";
import {useTransactions} from "~/app/stores/transaction";
import CurrentAvatar from "@/app/components/CurrentAvatar.vue";
import List from "@/app/components/List.vue";
import Page from "../../components/Page.vue";

const {upload, onError, onComplete} = useUpload();

const {files, open, reset, onChange} = useFileDialog({
  accept: 'image/*',
  directory: false,
  multiple: false
})

const route = useRoute()
const $api = useApi()
const auth = useAuth()
const store = useCurrentUser()
const transactions = useTransactions()
const {user} = storeToRefs(store)

watch(
    () => route,
    () => transactions.list(auth.userId),
    {immediate: true, deep: true}
)

// Fetch on mount
onMounted(() => {
  store.get()
})

// File upload handlers
onChange((fileList) => {
  if (fileList?.length)
    upload(fileList)
})

onError(err => console.log(err))

onComplete(async ({blob}) => {
  await store.update({
    avatar: blob.signed_id
  })

  reset()
})

</script>

<template>

  <Page>
    <!-- <template #title>
      Profile
    </template> -->
    <!-- <template #actions> -->
    <!-- TODO: edit profile action? Is anything editable aside from avatar?  -->
    <!-- </template> -->

    <div class="profile">
      <section class="profile__primary">
        <div class="user">
          <!-- <h2>User</h2> -->
          <div class="user__avatar">
            <CurrentAvatar />
            <a @click="open()" class="edit">
              <PencilIcon class="icon" />
            </a>
          </div>
          <div class="user__info">
            <span class="email">
              {{ user.email }}
            </span>
            <span class="joined">Member since: {{ new Date(user.createdAt).toLocaleDateString() }}</span>
          </div>
        </div>

        <div v-if="user.wallet && user.wallet.metrics" class="performance">
          <!-- <h2>Performance</h2> -->
          <div class="metrics">
            <div class="metric metric--success">
              <span class="label">Calls Won:</span><br/>
              <span class="value">{{ user.wallet.metrics.totalWins }}</span>
            </div>
            <div class="metric metric--failure">
              <span class="label">Calls Lost:</span><br/>
              <span class="value">{{ user.wallet.metrics.totalLosses }}</span>
            </div>
            <div class="metric">
              <span class="label">Win pctg:</span><br/>
              <span class="value">{{ user.wallet.metrics.winPercentage }}%</span>
            </div>
            <!-- <div class="metric">
              <span class="label">Total Calls:</span><br/>
              <span class="value">{{ user.wallet.metrics.totalBets }}</span>
            </div> -->

            <!-- <div class="metric metric--success">
              <span class="label">Points Won:</span><br/>
              <span class="value">{{ user.wallet.metrics.totalPointsWon }}</span>
            </div>
            <div class="metric metric--failure">
              <span class="label">Points Lost:</span><br/>
              <span class="value">{{ user.wallet.metrics.totalPointsLost }}</span>
            </div>
            <div class="metric">
              <span class="label">Average Points Wagered:</span><br/>
              <span class="value">{{ user.wallet.metrics.averageBetSize }}</span>
            </div> -->
          </div>
        </div>

        <!-- <div class="wallet">
          <h2>Wallet</h2>
          <div v-if="user.wallet">
            <div class="metrics">
              <div class="metric">
                <span class="label">Balance:</span><br/>
                <span class="value">{{ user.wallet.balance }}</span>
              </div>
              <div class="metric">
                <span class="label">Available Points:</span><br/>
                <span class="value">{{ user.wallet.availableBalance }}</span>
              </div>
              <div class="metric self-end">
                <button class="btn btn--positive">Add Points</button>
              </div>
            </div>
          </div>
          <div v-else>
            <p>error loading wallet info...</p>
          </div>
        </div> -->

      </section>

      <!-- <section class="profile__secondary">
        <div class="transactions">
          <h2>Transaction History</h2>
          <List :items="transactions.data">
            <template v-slot:default="item">
              <div :class="{debit: item.transactionType === 'debit', credit: item.transactionType === 'credit'}"
                   class="flex justify-between gap-x-6 py-5"
              >
                <div class="type"> <p> {{ item.transactionType }} </p> </div>
                <div class="amount"> <p> {{item.amount}} </p> </div>
                <div class="balance"> <p> {{ item.resultingBalance }} </p> </div>
              </div>
            </template>
          </List>
        </div>
      </section> -->
    </div>
  </Page>

</template>

<style scoped>

.profile {
  @apply text-center;
    /* TODO - default typographic styles */
  h2 {
    @apply
      text-2xl
      font-bold
      mb-2
  }
}

.profile__primary {
  @apply
    md:grid
    md:grid-cols-3 md:grid-rows-2
    md:gap-6 
    
    w-full
  ;
}

.profile__secondary {
  @apply
    pt-20
}

.user {
  @apply
    mb-10 md:mb-0

    md:col-span-1
    md:row-span-2
}

.user__avatar {
  @apply
    relative
    max-w-[250px]
    mx-auto md:mx-0
    mb-5
  ;

  .edit {
    @apply
      absolute top-0 -right-4 w-5 h-5 cursor-pointer
  }
}

.user__info {
  @apply
    text-center
    md:text-left
  ;

  span {
    @apply block;
  }

  .email {
    @apply
      mb-2
      text-xl
      font-bold
  }
  .joined {
    @apply
      italic
  }
}

.performance {
  @apply
    col-start-2
    col-span-2
    row-span-1
    mb-10
}

.wallet {
  @apply
    col-start-2
    col-span-2
    row-start-2
    row-span-1
}

.metrics {
  @apply
    flex
    flex-wrap
  ;
}

.metric {
  @apply
    basis-1/3
    md:basis-1/3

    mb-4
    /* md:mb-0 */
  ;

  .label {
    @apply
      text-xs;
  }

  .value {
    @apply
      text-xl
      font-bold
    ;
  }

  .btn {
    @apply
      mx-0
      ;
  }


  &--success {
    .value {
      @apply
        /* text-ci2-brand */
        text-utility-success
    }
  }

  &--failure {
    .value {
      @apply
        text-utility-error
    }
  }
}


.transactions {
  & .debit {
    & .type {

    }

    & .amount {
      @apply text-red-500;
    }

    & .balance {
      @apply text-red-500;
    }
  }

  & .credit {
    & .type {

    }

    & .amount {
      @apply text-green-500;
    }

    & .balance {
      @apply text-green-500;
    }
  }
}

</style>
