<script setup>
import {computed, h} from "vue";
import {RouterLink, useRoute} from 'vue-router'
import PageLink from "@/app/components/PageLink.vue";

const route = useRoute()

  const props = defineProps([
    'per',
    'prev',
    'next',
    'current', // current page
    'total', // total pages
    'count', // item count
    'first',
    'last',
  ])

  const pagesToShow = 4

  const window = computed(() => {
    let rightOffset, leftOffset;

    const windowSize = Math.floor(pagesToShow / 2)

    // the amount the window is greater than total pages
    rightOffset = (props.current + windowSize) - props.total
    rightOffset = Math.max(rightOffset, 0)

    // the amount the window is less than 1
    leftOffset = props.current - windowSize
    leftOffset = leftOffset <= 0 ? Math.abs(leftOffset) + 1 : 0

    // right and left edges of the window adjusted so there is always
    // a consistent # of items shown
    const right = props.current + (windowSize - rightOffset) + leftOffset
    const left = Math.max(props.current - (windowSize + rightOffset), 1)
    const length = right - left + 1

    // console.log('left', left, 'right', right, 'length', length, 'leftOffset', leftOffset, 'rightOffset', rightOffset)

    return [...Array.from(
        {length},
        (_, i) => i + left
    )]
  })

  const pages = computed(() => {
    return window.value.filter((i) => i <= props.total)
  })
</script>

<template>
  <nav class="pagination flex flex-row justify-start items-center gap-3" role="navigation" aria-label="pager">
    <template v-if='!first'>
      <PageLink class='first' page="1"> &Lt; </PageLink>
      <PageLink class="prev" :page="prev"> &lt; </PageLink>
    </template>

    <PageLink
        v-for='page in pages'
        class="page"
        :class="{current: page === current}"
        :page="page"
    >
      {{page}}
    </PageLink>

    <template v-if="!last">
      <PageLink class="next" :page="next"> &gt; </PageLink>
      <PageLink class="last" :page="total"> &Gt; </PageLink>
    </template>
  </nav>

</template>

<style scoped>

</style>