// JsFromRoutes CacheKey 429cc14e909a0059f568b9d93b9e8bfa
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper, defineEndpointHelper } from '~/app/services/apiFactory'
// api/v1/blueprints
export default defineEndpointHelper('api/v1/blueprints', {
generateSession: /* #__PURE__ */ definePathHelper('post', '/api/v1/blueprints/:id/generate_session'),
index: /* #__PURE__ */ definePathHelper('get', '/api/v1/blueprints'),
create: /* #__PURE__ */ definePathHelper('post', '/api/v1/blueprints'),
new: /* #__PURE__ */ definePathHelper('get', '/api/v1/blueprints/new'),
edit: /* #__PURE__ */ definePathHelper('get', '/api/v1/blueprints/:id/edit'),
show: /* #__PURE__ */ definePathHelper('get', '/api/v1/blueprints/:id'),
update: /* #__PURE__ */ definePathHelper('patch', '/api/v1/blueprints/:id'),
destroy: /* #__PURE__ */ definePathHelper('delete', '/api/v1/blueprints/:id'),
})