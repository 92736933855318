import Dash from "@/app/views/dashboard/Dash.vue";
import UserDash from "@/app/views/dashboard/UserDash.vue";


export const route = {
  path: '/dashboard',
  name: 'dashboard',
  component: Dash,
  meta: { redirectActive: true },
  children: [
    // Public
    {
      path: '',
      component: UserDash
    },
    {
      path: '/calls',
      component: UserDash
    },
    {
      path: '/events',
      component: UserDash
    },
  ]
}