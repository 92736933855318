import { createConsumer } from '@rails/actioncable'
import {computed, inject, onBeforeMount, onBeforeUnmount} from "vue";
import { setupDevtoolsPlugin } from '@vue/devtools-api';
import { createEventHook } from '@vueuse/core/index.cjs';

export const createCable = (app, options) => {
  return new Cable(app, options)
}

export function useCable() {
  return inject('$cable')
}

export const usePerform = (id) => {
  const $cable = useCable()

  return function(action, data) {
    return $cable.perform(id, action, data)
  }
}

/**
 * @param subscription
 * @param {Object} subscription.handler
 * @param {Object} subscription.identifier
 */
export const useChannel = ({handler, ...identifier}) => {
  const $cable = useCable()

  onBeforeMount(() => {
    $cable.subscribe(identifier, handler)
  })
  onBeforeUnmount(() => {
    $cable.unsubscribe(identifier)
  })

  return { perform: usePerform(identifier) }
}

export class ChannelMap {
  constructor() {
    this.subscriptions = new Map
  }

  get(id) {
    return this.subscriptions.get(this._id(id))
  }

  set(id, channel) {
    return this.subscriptions.set(this._id(id), channel)
  }

  delete(id) {
    return this.subscriptions.delete(this._id(id))
  }

  _id(id) {
    return typeof(id) === 'string' ? id : JSON.stringify(id)
  }
}


// class dt {
//
//   pluginId
//   pluginLabel
//
//   constructor(app, {pluginId, pluginLabel}) {
//     this.pluginId = pluginId
//     this.pluginLabel = pluginLabel
//     this.inspectorId = `${pluginId}.inspector`
//     this.app = app
//
//     this.stateHook = createEventHook()
//     this.treeHook = createEventHook()
//     this.on = {
//       inspectorState: this.stateHook.on,
//       inspectorTree: this.treeHook.on
//     }
//
//     this.setup()
//   }
//
//   setup() {
//     setupDevtoolsPlugin({
//       id: this.pluginId,
//       label: this.pluginLabel,
//       packageName: this.pluginLabel,
//       enableEarlyProxy: true,
//       app: this.app
//     }, (api) => {
//       this.api = api
//       this.register()
//     })
//   }
//
//   register() {
//     this.api.addInspector({
//       id: this.inspectorId,
//       label: this.pluginLabel
//     })
//
//     this.api.on.getInspectorTree((payload, context) => {
//       if (payload.inspectorId === this.inspectorId) {
//         this.treeHook.trigger(payload, context)
//       }
//     })
//
//     this.api.on.getInspectorState((payload, context) => {
//       if (payload.inspectorId === this.inspectorId) {
//         this.stateHook.trigger(payload, context)
//       }
//     })
//   }
//
//   sendInspectorState() {
//     this.api.sendInspectorState(this.inspectorId)
//   }
//
//   sendInspectorTree() {
//     this.api.sendInspectorTree(this.inspectorId)
//   }
// }

export class Cable {
  _cable = null
  _channels = new ChannelMap()

  constructor(app, options) {
    app.config.globalProperties.$cable = this
    app.provide('$cable', this)
    // this.__dtSetup(app)
    this.connect()
  }


  __dtSetup(app) {
    // const devtoolsId = 'ci.$cable'
    // const devtoolsLabel = '$cable'
    // const $cable = this

    // this.__dtApi = new dt(app, {
    //   pluginId: devtoolsId,
    //   pluginLabel: devtoolsLabel
    // })

    // this.__dtApi.on.inspectorState((payload, context) => {
    //   if (payload.nodeId === '_cable') {
    //     payload.state = {
    //       'Connection Status': [
    //         {
    //           key: '_url',
    //           value: $cable._cable.url
    //         }
    //       ]
    //     }
    //   }
    //   console.log(payload, context)
    //   if (payload.nodeId === '_channels') {
    //     payload.state = {
    //       Subscriptions: Array.from($cable._channels.subscriptions.entries()).map((k, v) => {
    //         const id = JSON.parse(id)
    //         return {
    //           key: id.channel,
    //           value: v
    //         }
    //       })
    //     }
    //   }
    // })

    // this.__dtApi.on.inspectorTree((payload, context) => {
    //   payload.rootNodes = [{
    //     id: '_cable',
    //     label: 'Connection Status',
    //   }, {
    //     id: '_channels',
    //     label: 'Channel Map',
    //     // children: [
    //     //   {
    //     //     id: 1,
    //     //     label: 'One',
    //     //     value: [1, 2, 3]
    //     //   }
    //     // ]
    //     children: Array.from($cable._channels.subscriptions.entries()).map(([k, v]) => {
    //       const id = JSON.parse(k)
    //       return {
    //         id: id.id,
    //         label: id.channel
    //       }
    //     })
    //   }]
    // })
    //
    // this.__dt = app.config.globalProperties.$CIDevTools
  }

  /**
   * given a subscription passed by a component register the handler
   * with the cable and store in the subscriptions map
   * @param {Object} subscription channel subscription object
   * @param {String} subscription.channel name of the channel
   * @param {...object} subscription.identifier rest of properties make up the identifier
   * @param handler {Object} handler object
   */
  subscribe(subscription, handler) {

    // console.log('NEW CHANNEL SUB', subscription, this._channels, this._cable.subscriptions)

    const wrappedHandler = {
      ...handler, received: (args) => {

        // this.__dt.emit(`${subscription['channel']}-${subscription['identifier']}-received`, {
        //   data: {
        //     info: 'received',
        //     ...subscription
        //   }
        // })

        return handler.received(args)
      }
    }

    // this.__dt.emit('$cable subscribe', {
    //   data: { info: 'subscribe', ...subscription },
    // })

    const existing = this._channels.get(JSON.stringify(subscription))

    if (!existing) {
      const sub = this._cable.subscriptions.create(subscription, handler)

      this._channels.set(
        sub.identifier,
        sub
      )
    }

    // this.__dtApi.sendInspectorTree()
  }

  /**
   * Unsubscribe a channel
   * @param id identifier of the channel to unsubscribe from
   */
  unsubscribe(id) {
    console.log('UNSUBSCRIBE', id)
    this._channels.get(id).unsubscribe()
    this._channels.delete(id)

    // this.__dt.emit('$cable unsubscribe', {
    //   data: { info: 'unsubscribe', ...id },
    // })

    // this.__dtApi.sendInspectorTree()
  }

  /**
   * Perform an action on the action cable server
   * @param id {Object|String} identifier of the channel
   * @param action {String} action to perform
   * @param data {Object} action payload
   */
  perform(id, action, data) {
    // this.__dt.emit('$cable perform', {
    //   data: { id, action, data },
    // })

    this._channels
        .get(id)
        .perform(action, data)
  }

  /**
   * Connect to the action cable server
   */
  connect() {

    this._cable = createConsumer()

    // this.__dt.emit('$cable connect', {
    //   data: { info: 'connect', $cable: this._cable }
    // })

    // this.__dtApi.sendInspectorTree()
  }

  /**
   * Disconnect from the action cable server
   */
  disconnect() {

    // this.__dt.emit('$cable disconnect', {
    //   data: { info: 'disconnect' }
    // })

    this._cable.disconnect()

    // this.__dtApi.sendInspectorState()
  }

  /**
   * Reconnect to the action cable server
   */
  reconnect() {
    this._cable.connect()
  }
}