<script setup>
import {onMounted, ref, defineModel} from "vue";
import {useUpload} from "@/app/services/useUpload";
import {PhotoIcon} from "@heroicons/vue/24/outline";
import {unrefElement} from "@vueuse/core";

const model = defineModel();
const props = defineProps(['sessionId']);
const emits = defineEmits(['created'])
// const {onChange} = useUpload()
const focusEl = ref(null)
onMounted(() => unrefElement(focusEl).focus())
</script>

<template>
  <form class='form' name="newEvent" @submit.prevent='() => emits("created")'>
    <legend tabindex='-1' class="form-title">
      New Event <br> <span class="text-sm">At position: {{ model.position + 1 }}</span>
    </legend>

    <div class="field">
      <label for="name" class="text-sm font-medium leading-6 text-white">Question Label</label>
      <input id="name"
              ref="focusEl"
              tabindex="0"
              name='name'
              v-model="model.name"
              type="text"
              required>
    </div>

    <fieldset class="fieldset">
      <legend>True Spot</legend>

      <!-- <div class="field col-span-1 sm:col-span-2">
        <label for="odds" class="block text-sm font-medium leading-6 text-white"> Odds </label>
        <div class="mt-2">
          <input id="odds"
                 tabindex="0"
                 name='name'
                 v-model="model.trueOutcome.odds"
                 type="text">
        </div>
      </div> -->

      <!-- <div class="field field--file">
        <label for="trueImage"> Image </label>

        <div class="input-wrap">
          <PhotoIcon class="icon" />
          <input
                  id="trueImage"
                  name='trueImage'
                  @change="onChange"
                  type="file">
        </div>

        <span v-if=model.trueImagePreview>
          <img :src="model.trueImagePreview" alt="True spot image preview">
        </span>
      </div> -->

      <div class="field">
        <label for="trueLabel"> Label </label>
        <input id="trueLabel"
                name='trueLabel'
                v-model="model.trueOutcome.label"
                type="text"
                required>
      </div>

    </fieldset>

    <fieldset class="fieldset">

      <legend>False Spot</legend>


      <!-- <div class="field col-span-1 sm:col-span-2">
        <label for="odds" class="block text-sm font-medium leading-6 text-white"> Odds </label>
        <div class="mt-2">
          <input id="odds"
                 tabindex="0"
                 name='name'
                 v-model="model.falseOutcome.odds"
                 type="text">
        </div>
      </div> -->

      <!-- <div class="field field--file">
        <label for="falseImage"> Image </label>

        <div class="input-wrap">
          <PhotoIcon class="icon" />
          <input id="falseImage"
                  name='falseImage'
                  @change="onChange"
                  type="file">
        </div>

        <span v-if=model.falseImagePreview>
          <img :src="model.falseImagePreview" alt="False spot image preview">
        </span>
      </div> -->

      <div class="field">
        <label for="falseLabel"> Label </label>
        <input id="falseLabel"
                name='falseLabel'
                v-model="model.falseOutcome.label"
                type="text"
                required>
      </div>
    </fieldset>

    <fieldset class="fieldset">
      <legend>Resolution</legend>
      <div class="field field--radio">
        <label>
          <input id="result"
            name="resolution"
            v-model="model.resolution"
            type="radio"
            :value="true"
          >
          <span>{{model.trueOutcome?.label ?? "True"}}</span>
        </label>
        <label>
          <input id="result"
            name="resolution"
            v-model="model.resolution"
            type="radio"
            :value="false"
          >
          <span>{{model.falseOutcome?.label ?? "False"}}</span>
        </label>
      </div>
    </fieldset>

    <fieldset class="fieldset">
      <legend>Timing</legend>
      <div class="field">
        <label for="duration">Duration <span class="text-sm italic">(seconds)</span></label>
        <input id="duration"
                tabindex="0"
                name='duration'
                v-model="model.duration"
                type="number"
                min="30"
                max="300"
                step="30"
                required
                >
      </div>
    </fieldset>

    <div class="actions">
      <button type="submit" class="btn--positive">Submit</button>
    </div>

  </form>
</template>

<style scoped>

.form {
  .form-title {
    @apply
      text-3xl text-ci2-brand font-semibold
  }
}

.fieldset {
  @apply
    border-l border-white/10 mt-6 mb-2 col-span-1 sm:col-span-2
  ;

  legend {
    @apply
      block
      bg-call-it-300
      text-ci-primary
      px-2
  }

  .field {
    @apply pl-4
  }
}

.field {
  @apply my-4;

  label {
    @apply
      block text-sm font-medium leading-6 text-white
  }

  input {
    @apply
      mt-2 w-full 
  }
}

.field--file {
  .input-wrap {
    @apply
      flex items-center gap-x-3;

    
      .icon {
        @apply
          mx-auto h-12 w-12 text-gray-500;
      }
  }

  input {
    @apply 
      block py-1.5 
  }
}

.field--radio {
  label {
    @apply
      flex
      items-center
      mt-2
  }
  input {
    @apply
      w-4
      mt-0 mr-4
  }
}


.actions {
  @apply mt-6;
}
</style>