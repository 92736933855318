import { readonly, ref, computed } from "vue";
import { camel } from "radash";
import * as routeClient from "@js-from-routes/client";
import { useRequestState } from "@/app/composable/useRequestState";

export const definePathHelper = (method, pathTemplate) => {
  const helper = routeClient.definePathHelper(method, pathTemplate)

  const { wrap, ...requestState } = useRequestState()
  const locals = { ...requestState, params: pathTemplate.match(/:(\w+)/g)?.map(x => camel(x.slice(1))) ?? undefined }

  return new Proxy(helper, {
    apply(target, thisArg, [params, transformer = (data) => data]) {

      if (typeof params === 'function') {
        transformer = params
        params = {}
      }

      if (target.name in locals) {
        return Reflect.apply(locals[target.name], thisArg, params)
      }

      return wrap(async () => {
        const result = await target.apply(thisArg, [params])
        console.log('target', target)
        console.log('api result: ', result)
        return transformer(result)
      })
    },
    get(target, prop, receiver) {
      if (prop in locals) {
        return Reflect.get(locals, prop, receiver)
      }

      return Reflect.get(target, prop, receiver)
    }
  })
}


export const defineEndpointHelper = (controllerName, definitions) => {
  let controller = controllerName.split('/').at(-1)
  if (controller.endsWith('s')) controller = controller.slice(0, -1)


  const loading = computed(() => {
    return Object
      .values(definitions)
      .map((x) => x.loading.value)
      .some(Boolean)
  })

  return {
    controller,
    loading,
    ...definitions,
  }
}
