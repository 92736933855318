// JsFromRoutes CacheKey bf769cc6137a8d0bb70522fe9d055543
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper, defineEndpointHelper } from '~/app/services/apiFactory'
// api/v1/sessions
export default defineEndpointHelper('api/v1/sessions', {
activate: /* #__PURE__ */ definePathHelper('put', '/api/v1/sessions/:id/activate'),
deactivate: /* #__PURE__ */ definePathHelper('put', '/api/v1/sessions/:id/deactivate'),
pause: /* #__PURE__ */ definePathHelper('put', '/api/v1/sessions/:id/pause'),
resume: /* #__PURE__ */ definePathHelper('put', '/api/v1/sessions/:id/resume'),
start: /* #__PURE__ */ definePathHelper('put', '/api/v1/sessions/:id/start'),
stop: /* #__PURE__ */ definePathHelper('put', '/api/v1/sessions/:id/stop'),
index: /* #__PURE__ */ definePathHelper('get', '/api/v1/sessions'),
create: /* #__PURE__ */ definePathHelper('post', '/api/v1/sessions'),
show: /* #__PURE__ */ definePathHelper('get', '/api/v1/sessions/:id'),
update: /* #__PURE__ */ definePathHelper('patch', '/api/v1/sessions/:id'),
destroy: /* #__PURE__ */ definePathHelper('delete', '/api/v1/sessions/:id'),
})