import { watch } from 'vue'
import { useRoute } from 'vue-router'

export function watchRoute(onRoute, options = {}) {
    const route = useRoute()
    const routeGetter = () => route
    const opts = {
        immediate: true,
        deep: false,
        ...options
    }

    return watch(
        routeGetter,
        onRoute,
        opts
    )
}