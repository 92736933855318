<script setup>
import {useScore} from "@/app/stores/score";
import {useRoute} from "vue-router";
import {watch, watchEffect} from "vue";
import Leaderboard from "@/app/components/Leaderboard.vue";
import Page from "../../components/Page.vue";

const route = useRoute()
const store = useScore()

watch(() => [route.query.page, route.path],
    () => {
      store.list({page: route.query.page})
    },
    {immediate: true, deep: true}
)

</script>

<template>
  <Page>
    <template #title>Leaderboard</template>
    <template #actions></template>

    <Leaderboard />
  </Page>
</template>

<style scoped></style>