import { defineStore } from "pinia";
import { useCrud } from "@/app/composable/useCrud";
import { $api } from "@/app/services/api";

export const useScore = defineStore('score', () => {
  const crud = useCrud($api.apiV1Scores, { name: "score" });
  const data = crud.data;
  const meta = crud.meta;
  const loading = crud.loading;
  const error = crud.error;

  return {
    data,
    meta,
    index: crud.index,
    loading,
    error,
  };
});