<script setup>

import {onMounted, ref} from "vue";
import Page from "@/app/components/Page.vue";
// import BlueprintEvent from "@/app/components/blueprint/BlueprintEvent.vue";

import { useDraggable } from "@vueuse/core";
import {useApi} from "@/app/services/api";

const api = useApi()

onMounted( async () => {
  const bp = await api.apiV1Blueprints.index()

  console.log(bp)
})


class Event {
  constructor(position) {
    this.type = 'bool'
    this.text = 'This is a question?'
    this.duration = 60
    this.position = position
  }
}

class Blueprint {
  constructor() {
    this.name = 'Blueprint'
    this.questions = Array(3).fill(null).map(() => new Event())
  }
}

const blueprint = ref(new Blueprint())
const events = ref([])

onMounted(() => {
  Array(10).fill(null).map((x, i) => events.value.push(new Event(i)))
})

function addEvent(pos) {
  const arr = events.value
  arr.splice(pos, 0, new Event(pos))
  events.value = arr
}

function removeEvent(pos) {
  const arr = events.value
  arr.splice(pos, 1)
  events.value = arr
}

function createSession() {
  console.log('create session')
}

</script>

<template>
<div class="blueprint">
  <div class="blueprint__header">
    <h1> Blueprint </h1>
    <button @click="createSession()" class="btn btn--positive"> Copy to Session </button>
    <!-- <button @click="addEvent(0)"> Add Event </button> -->
  </div>

  <div class="blueprint__events">
    <div class="bp-event__add">
      <button @click="addEvent(i + 1)"> (+) Add Event </button>
    </div>
    
    <div class="bp-event" v-for="(event, i) in events" :key="i">
      <div class="bp-event__wrap">
        <!-- <div> t + {{event.duration}} </div> -->
        <!-- <BlueprintEvent class='contents' :position="i"  v-model="events[i]" /> -->
        <button @click="removeEvent(i)"> (X) </button>
      </div>
      <div class="bp-event__add">
        <button @click="addEvent(i + 1)"> (+) Add Event </button>
      </div>
    </div>

    <!--      <button @click="up(i)"> up </button>-->
    <!--      <button @click="down(i)"> down </button>-->

  </div>

</div>
</template>

<style scoped>
  .blueprint {
    @apply
      mx-auto mt-4;
  }

  .blueprint__header {
    @apply
      mb-10;

    h1 {
      @apply
        text-2xl
        font-bold
    }

    .btn {
      @apply
        ml-0
        mt-4
    }
  }

  .bp-event {
    @apply
      mt-2
  }

  .bp-event__wrap {
    @apply
      flex gap-4 items-center
  }

  .bp-event__add {
    @apply
      text-center
      mt-8
      border-t
      border-dotted
    ;

    button {
      @apply
        bg-ci2-black
        transform translate-y-[-0.9em]
        px-2
        hover:text-ci2-brand
    }
  }

</style>