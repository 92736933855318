><script setup>
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import BlueprintForm from './BlueprintForm.vue';
import { useBlueprint } from '@/app/stores/blueprint';
import Page from '@/app/components/Page.vue';
import { watchRoute } from '@/app/composable/useRouteWatcher';
import { storeToRefs } from "pinia"


const store = useBlueprint();
const { blueprint } = storeToRefs(store);
const route = useRoute();
const router = useRouter();

watchRoute(
	async () => {
    console.log('watch route', route.params.id)
    await store.show(route.params.id)
	}
)

const updateBlueprint = async ({ id, name }) => {
	console.log('update: ', {id, name})
	await store.update({id, name});
	await returnTo()
}

async function cancelUpdate()  {
	await returnTo()
}

async function returnTo() {
  router.push(`/blueprints/${route.params.id}`);
  await router.isReady();
}

</script>

<template>
	<Page>
		<template #title>Edit Blueprint</template>
		<BlueprintForm v-model="blueprint" @update:modelValue="updateBlueprint" @cancel="cancelUpdate" />
	</Page>
</template>