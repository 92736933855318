<script setup>
import { Disclosure, DisclosurePanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { nextTick, reactive, ref } from "vue";
import { onClickOutside, unrefElement } from "@vueuse/core";
import {useCurrentUser} from "@/app/stores/currentUser";

const dialog = ref(null)
const confirmEl = ref(null)
const isOpen = ref(false)
const state = reactive({})
const choice = ref('');
const emits = defineEmits(['confirm', 'increase', 'decrease'])

const { user: {wallet} } = useCurrentUser();

const transProps = {
  'enter': "ease-out duration-300",
  'enter-from': "opacity-0 translate-y-1",
  'enter-to': "opacity-100 translate-y-0",
  'leave': "ease-in duration-200",
  'leave-from': "opacity-100 translate-y-0",
  'leave-to': "opacity-0 translate-y-1"
}

function open(props, targetEl) {
  isOpen.value = true
  choice.value = props.label
  nextTick(() => {
    Object.assign(
        state,
        props,
    )
    unrefElement(confirmEl).focus({focusVisible: true})
  })
}

const close = () => isOpen.value = false
const confirm = async () => {
  emits('confirm', state);
}

onClickOutside(dialog, close)
defineExpose({ open, close, isOpen })

function incrementWager(increase = true) {
  if (increase && state.wager < wallet.availableBalance) {
    state.wager ++;
  } else if (!increase && state.wager > 1) {
    state.wager --;
  }
}

</script>

<template>

<Disclosure>
  <TransitionRoot as="template" :show="isOpen">
    <DisclosurePanel ref="dialog" as="div" class="relative z-10" static>
      <TransitionChild as="template" v-bind='transProps'>
        <div class="dialog">
          <div class="dialog__copy">
            <p class="mt-4 mb-8">You chose: <span class="font-bold underline">{{ choice }}</span></p>

            <!-- TODO - implement WagerCtrl component -->
             
            <!-- <div class="wager-form">
              <label>Wager</label>
              <span class="balance">available tokens: {{ wallet.availableBalance }}</span>
              <div class="controls">
                <button class="decrease" @click="() => incrementWager(false)">-</button>
                <div class="amount">
                  <span>{{ state.wager }}</span>
                </div>
                <button class="increase" @click="() => incrementWager(true)">+</button>
              </div>
            </div> -->
          </div>
          <div class="dialog__actions">
            
            <button ref='confirmEl' class="btn btn--positive" @click="confirm()">Confirm Call</button>
            <button class="btn btn--neutral" @click="close()">Cancel</button>

          </div>
        </div>
      </TransitionChild>
    </DisclosurePanel>
  </TransitionRoot>
</Disclosure>


</template>

<style scoped>

.dialog {
  @apply
    w-full
    mt-4
    p-4 pb-6
    bg-ci-primary

    border-2 border-ci2-brand-lighter
    rounded-sm
  ;
}

.dialog__copy {
  @apply
    text-xl
    text-center
    mb-4
}

.dialog__actions {
  @apply
    flex
    gap-4
    justify-center
  ;

  .btn {
    @apply
      m-0
  }
}


.wager-form {
  label {
    @apply
      block
      text-3xl
      ;
  }
  .balance {
    @apply
      block
      text-sm
      mt-2 mb-8
  }

  .controls {
    @apply
      flex
      justify-center
  }

  .amount {
    @apply
      flex
      w-14 h-14
      mx-4

      rounded-full
      border-2
      border-ci2-brand
    ;

    span {
      @apply
        m-auto
        text-2xl
    }
  }

  button {
    @apply
      text-4xl
  }
}

</style>