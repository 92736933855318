import { defineStore } from "pinia";
import { useCrud } from "@/app/composable/useCrud";
import { $api } from "@/app/services/api";
import { useChannel } from "@/app/services/cable";
import { markRaw, ref } from "vue";

export function createChatStore(chatId) {
  return defineStore(`chat_${chatId}`, () => {
    const { data, meta, loading, error, collection, index, create } = useCrud($api.apiV1Messages, { name: "message" });

    const channel = useChannel({
      channel: 'ChatChannel',
      context_id: chatId,
      handler: {
        received: ({ detail: { message: res } }) => {
          message.value = $api.Config.deserializeData(res);
          collection.update(message.value);
        }
      }
    });


    const perform = markRaw(channel.perform);
    const message = ref('')

    const send = () => {
      console.log('send', message)
      perform('speak', { msg: message.value });
      message.value = '';
    }

    return {
      message,
      data,
      meta,
      index,
      create,
      send,
      loading,
      error,
    };
  })();
}