<script setup>
import Event from "@/app/components/events/Event.vue";
import { computed } from 'vue'
import { useCurrentSession } from "../store";
import { storeToRefs } from "pinia"

const store = useCurrentSession()

const events = computed(() => {
  return store.events.filter((e) => e.visibility === 'visible')
})

</script>

<template>
  <template v-if="events">
    <div class="action-graphic">
      <!-- <img src="@images/action-graphic.png"/> -->
      <span>ACTION</span>
    </div>

    <TransitionGroup name="events">
      <div v-for="event in events" :key="event.position">
        <Event
            v-bind="event"
            v-model:call="event.call"
        />
      </div>
    </TransitionGroup>
  </template>
</template>

<style scoped>

.events-move {
  @apply transition-all ease-out;
}

.events-enter-active {
  @apply ease-out duration-300;
}

.events-leave-active {
  @apply ease-in duration-300 absolute;
}

.events-enter-from,
.events-leave-to {
  opacity: 0;
}


.action-graphic {
  @apply
    text-5xl
    text-center
    pt-8 pb-12
    tracking-wider
    ;

  /* tailwind doesn't provide color utilities for drop-shadow / text-shadow */
  filter: drop-shadow(0 0px 40px rgba(50,200,218, 0.9)) drop-shadow(0 0px 10px rgba(50,200,218, 0.8));
}


</style>