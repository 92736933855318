import { computed, ref } from "vue";
import { defineStore } from "pinia";

import { $api } from "@/app/services/api";
import { useAuth } from "@/app/stores/auth";
import { useEvents } from "@/app/stores/event";
import { useCalls } from "@/app/stores/calls";
import { userChannelStore } from "@/app/stores/channel";
import { reactiveComputed, useArrayFilter, useArrayMap } from "@vueuse/core";
import { useRequestState } from "@/app/composable/useRequestState";


export const useCurrentUser = defineStore('currentUser', () => {
  const $endpoint = $api.apiV1Users
  const channel = userChannelStore()
  const calls = useCalls()
  const auth = useAuth()
  const eventStore = useEvents()
  const state = useRequestState()
  const user = ref({
    calls: [],
    sessions: [],
    email: null,
    gid: null,
    avatar: null,
    id: null,
    notificationCount: 0,
    notifications: [],
    phone: null,
    updatedAt: null,
    username: null,
    wallet: {
      balance: 0,
      availableBalance: 0,
      id: null,
      metrics: {}
    }
  })


  auth.$onAction(({ name, store, args, after, onError }) => {
    if (name === 'signOut') after(() => channel.unsubscribe())
    if (name === 'signIn') after(async (userDetail) => {
      console.log('SIGN IN', userDetail)
      await get()
      await subscribe(userDetail)
    })
  })

  async function subscribe({ userGid }) {
    console.log('SUBSCRIBE', userGid)
    const mapper = {
      close_call: ({ call }) => {
        console.log('CLOSE CALL', call)
        channel.emit(`call:close`, call)
      },
      resolve_call: ({ call }) => {
        console.log('RESOLVE CALL', call)
        channel.emit(`call:resolve`, call)
      },
      balance_change: ({ wallet }) => {
        user.value = { ...user.value, wallet }
        channel.emit('balance:update', wallet)
      }
    }

    channel.subscribe(userGid, ({ eventType, detail }) => {
      if (mapper[eventType]) {
        mapper[eventType](detail)
      }
    })
  }

  async function get() {
    const res = await $endpoint.me({})
    user.value = res.user
    calls.$patch({ data: res.user.calls })
  }

  async function update(payload) {
    const response = await $endpoint.update({
      params: { id: user.value.id },
      data: { user: payload }
    })

    Object.assign(user.value, response.user)
  }

  const events = reactiveComputed(() => {

    return []
    // return eventStore.action.map((event) => {
    //   return {
    //     ...event,
    //     call: calls.byEvent(event.id) || {wager: 1}
    //   }
    // })
  })

  return {
    loading: state.loading,
    error: state.error,
    calls: calls.data,
    events,
    get,
    update,
    user,
    subscribe: channel.subscribe,
    unsubscribe: channel.unsubscribe,
    off: channel.off,
    on: channel.on
  }
})