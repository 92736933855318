import {ref, readonly} from "vue";
import {createEventHook} from "@vueuse/core";

export const useRequestState = () => {
  const loading = ref(false)
  const error = ref(null)
  const data = ref(null)

  const onResult = createEventHook()
  const onError = createEventHook()
  const onLoading = createEventHook()

  const wrap = async (fn) => {
    error.value = null
    loading.value = true
    onLoading.trigger(true)

    try {
      const res = await fn()
      data.value = res
      onResult.trigger(res) 
      return res
    } catch(e) {
      error.value = e
      onError.trigger(e)
      throw(e)
    } finally {
      loading.value = false
      onLoading.trigger(false)
    }
  }

  return {
    wrap,
    data: readonly(data),
    loading: readonly(loading),
    error: readonly(error),
    onResult: onResult.on,
    onError: onError.on,
    onLoading: onLoading.on
  }
}