// JsFromRoutes CacheKey 51c0f1eb863a2cd97b499638b00cc2bc
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper, defineEndpointHelper } from '~/app/services/apiFactory'
// api/v1/events
export default defineEndpointHelper('api/v1/events', {
advance: /* #__PURE__ */ definePathHelper('put', '/api/v1/events/:id/advance'),
skip: /* #__PURE__ */ definePathHelper('put', '/api/v1/events/:id/skip'),
delay: /* #__PURE__ */ definePathHelper('put', '/api/v1/events/:id/delay'),
index: /* #__PURE__ */ definePathHelper('get', '/api/v1/events'),
create: /* #__PURE__ */ definePathHelper('post', '/api/v1/events'),
update: /* #__PURE__ */ definePathHelper('patch', '/api/v1/events/:id'),
destroy: /* #__PURE__ */ definePathHelper('delete', '/api/v1/events/:id'),
})