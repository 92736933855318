import { defineStore } from "pinia"
import { computed } from "vue"
import { $api } from "@/app/services/api"
import { useCrud } from "@/app/composable/useCrud"
import { isFunction, pick } from "radash"

export const useBlueprint = defineStore("blueprint", () => {
  const crud = useCrud($api.apiV1Blueprints, { name: "blueprint" })
  const blueprints = crud.data

  const eventCrud = useCrud($api.apiV1BlueprintEvents, {
    name: "blueprintEvent"
  })
  const { data: events, model: blueprint } = eventCrud

  const orderedEvents = computed({
    get() {
      let newEvents = (events?.value ?? []).sort(
        (a, b) => a.position - b.position
      )
      // console.log("ordered", newEvents)

      return newEvents
    },
    set(value) {
      events.value = value
    }
  })

  async function generateSession(id) {
    return await crud.endpoint.generateSession({ id }, res => res.session)
  }

  async function show(id) {
    if (!id) return null

    return await crud.show({ id }, data => {
      blueprint.value = data
      events.value = data.blueprintEvents

      return blueprint
    })
  }

  const {
    create: createEvent,
    update: updateEvent,
    destroy: destroyEvent
  } = {
    ...eventCrud
  }

  return {
    // state
    blueprints,
    blueprint,
    events,
    orderedEvents,
    // blueprint actions
    ...pick(crud, ["create", "update", "destroy", "index"]),
    show,
    generateSession,
    // event actions
    createEvent,
    updateEvent,
    destroyEvent
  }
})
