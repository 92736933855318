import * as VueRouter from 'vue-router';
import { useAuth } from './stores/auth';
import { toValue } from 'vue';
import GuestInterstitial from './components/GuestInterstitial.vue';

function routes() {
  const views = import.meta.glob('./views/*/index.js', { eager: true })

  return Object.values(views).map((v) => v.route)
}

export const authGaurd = async (to, from) => {
  if (to.meta.requiresAuth) {
    if (!toValue(useAuth().isAdmin)) {
      return false
    }
  }
}

export const guestGaurd = async (to, from) => {
  if (!to.meta.guestAccessible) {
    if (toValue(useAuth()).isGuest) {
      return { name: 'guestInterstitial' }
    }
  }
}


export const createRouter = () => {
  const _routes = routes()

  const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes: [
      {path: '/', redirect: '/blueprints', name: 'rootRedirect'},
      {
        name: 'guestInterstitial',
        path: '/guest',
        meta: {
          guestAccessible: true
        },
        component: GuestInterstitial,
      },
      ..._routes
    ]
  })

  router.beforeEach((to, from) => console.log(from.fullPath, ' -> ', to.fullPath))
  router.beforeEach(authGaurd)
  router.beforeEach(guestGaurd)

  return router
}


