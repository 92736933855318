import { markRaw, ref, toValue } from "vue"
import mitt from "mitt"
import { useCable } from "@/app/services/cable"
import { Config } from '@js-from-routes/client'

export const useChannel = () => {
  const $cable = useCable()
  const bus = markRaw(mitt())
  const identifier = ref()

  function subscribe(id) {
    identifier.value = id
    $cable.subscribe(toValue(identifier), {
      async received({ sourceType, eventType, detail }) {
        const event = `${sourceType}:${eventType}`
        console.log('RECEIVED', event, detail)
        await bus.emit(event, Config.deserializeData(detail))
      }
    })
  }

  function unsubscribe() {
    $cable.unsubscribe(toValue(identifier))
    bus.off('*')
  }

  return {
    subscribe,
    unsubscribe,
    emit: bus.emit,
    all: bus.all,
    off: bus.off,
    on: bus.on
  }
}
