<script setup>
import { useEvents } from "~/app/stores";
import { PencilIcon, TrashIcon, ClockIcon, ArrowDownIcon, ArrowUpIcon, ChevronDoubleLeftIcon } from '@heroicons/vue/24/solid';

const props = defineProps(['event', 'updateMethod', 'destroyMethod', 'showUpdate']);

const store = useEvents();

async function fireStateEvent(state, result=null) {
  await store.fireStateEvent({
    stateEvent: state,
    result: result,
    eventId: props.event.id
  });
}

async function repositionEvent({id, position}, increase = true) {
	const newPosition = increase ? position + 1 : position - 1;
	console.log('update', {id, position: newPosition});
	await store.update({id, position: newPosition});
}

</script>

<template>
<div class="event">
	<div class="event__info">
    <span class="state" :class="event.visibility"> {{event.visibility}} </span>
		<span class="state" :class="event.state">{{ event.state }}</span>
		<span class="duration">
				<ClockIcon class="icon"/>
				{{ event.duration }}s
		</span>
		<div class="reorder">
			<button @click="repositionEvent(event, false)" v-if="event.position > 1">
				<ArrowUpIcon />
			</button>
			<button @click="repositionEvent(event, true)">
				<ArrowDownIcon />
			</button>
		</div>
	</div>
	<div class="event__name">
		<span>{{ event.name }}</span>
	</div>
	<div class="event__outcomes">
		<span class="trueOutcome">
			<span class="label">True Label:</span>
			{{ event.trueOutcomeLabel }}
			<span class="resolution" v-if="event.resolution">
				<ChevronDoubleLeftIcon class="icon"/>
				Resolution
			</span>
		</span>
		<span class="falseOutcome">
			<span class="label">False Label:</span>
			{{ event.falseOutcomeLabel }}
			<span class="resolution" v-if="!event.resolution">
				<ChevronDoubleLeftIcon class="icon"/>
				Resolution
			</span>
		</span>
		<span class="result">
			<span class="label">Result: </span>
			{{ event.result ?? "undetermined" }}
		</span>
	</div>

	<div class="event__controls">
		<!-- Transitions -->

		<div class="transitions">
			<!-- {{ event.stateEvents }} -->
			<!-- resolutions -->
			<template v-for="stateEvent in event.stateEvents">
				<template v-if="stateEvent === 'close_event'">
					<div class="resolutions">
						<button class="btn btn--positive" @click="fireStateEvent(stateEvent, event.trueSpot.value)" >
							<span class="text-lg md:text-xl lg:text-2xl"> {{event.trueSpot.label}} </span>

							<img v-if="event.trueSpot.imageMini" :src="event.trueSpot.imageMini" alt="resolve true spot icon" />
						</button>
						<button class="btn btn--positive" @click="fireStateEvent(stateEvent, event.falseSpot.value)" >
							<span class="text-lg md:text-xl lg:text-2xl"> {{event.falseSpot.label}} </span>

							<img v-if="event.falseSpot.imageMini" :src="event.falseSpot.imageMini" alt="resolve false spot icon" />
						</button>
					</div>
				</template>
				
				<!-- everything else (publish / destroy, reset / resolve / destroy) -->
				<button v-else :class="`btn${stateEvent == 'hide' ? ' btn--negative' : ' btn--neutral'}`" @click="fireStateEvent(stateEvent)">
					<span class="text-lg md:text-xl lg:text-2xl"> {{ $t(`ci.event.events.${stateEvent}`) }} </span>
				</button>
			</template>

      <!-- Visibility Events -->
      <template v-for="stateEvent in event.visibilityEvents">
        <button :class="`btn${stateEvent == 'hide' ? ' btn--negative' : ' btn--neutral'}`" @click="fireStateEvent(stateEvent)">
          <span class="text-lg md:text-xl lg:text-2xl"> {{ $t(`ci.event.events.${stateEvent}`) }} </span>
        </button>
      </template>
		</div>
		<!-- <button v-if="showUpdate" @click="updateMethod(event)"><PencilIcon class="edit"/></button>
		<button @click="removeEvent(event.id, event.position)"> <TrashIcon class="trash" /></button> -->
	</div>
</div>
</template>

<style scoped>

.event {
	@apply
		w-full;

	span.label {
		@apply
			text-ci2-grey2
			text-sm
			mr-2
	}
}

.event__info {
	@apply
		flex
		items-center gap-4
		mb-2
	;

	svg {
		@apply
			w-6
			mr-1
	}

	.duration {
		@apply
			flex
			items-center
		;
	}

	.state {
		@apply 
			block
			w-fit
			
			px-1
			rounded-md
			text-base
		;
		&.open {
			@apply bg-utility-success
		}
		&.draft {
			@apply bg-utility-warn
		}
		&.closed {
			@apply bg-utility-error
		}
	}

	.reorder {
		@apply
			flex
			ml-auto
		;
	}
}

.event__name {
	@apply
		text-2xl
		mb-2
		max-w-full
		break-words
}

.event__outcomes {
	@apply
		flex flex-col
		mb-6
	;

	.resolution {
		@apply
			text-ci2-brand
			text-sm;
	}
	.icon {
		@apply
			inline
			w-5
			pb-1
			fill-ci2-brand;
	}

	.result {
		@apply
			text-sm
			mt-1
	}
}


.event__controls {
	@apply

		flex
		justify-between
	;

	.trash, .edit {
		@apply
			w-5
			ml-5
	}

	.trash {
		@apply
			fill-utility-error
	}

	.transitions {
		@apply
			flex gap-2;
	}
	.resolutions {
		@apply
			flex gap-2;
	}
}

</style>