<script setup>

import {Dialog as HeadlessDialog, DialogPanel, TransitionChild, TransitionRoot} from "@headlessui/vue";
import {XMarkIcon} from "@heroicons/vue/16/solid";

const isOpen = defineModel('open')

</script>

<template>

  <TransitionRoot as="template" :show="isOpen">

    <HeadlessDialog
        as="div"
        class="fixed inset-0 z-10 overflow-y-auto"
        @close="isOpen = false">

      <!-- Overlay -->
      <TransitionChild
          as="div"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-50"></div>
      </TransitionChild>

      <!-- Dialog content -->
      <div class="flex items-center justify-center min-h-screen">
        <TransitionChild
            as="div"
            enter="ease-out duration-300"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">

          <DialogPanel class="w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-ci-secondary shadow-xl rounded-2xl">

            <button @click="isOpen = false"
                    tabindex="-1"
                    class="absolute top-2 right-2">
                      <span class="text-xl leading-none">
                        <XMarkIcon class="mx-auto h-10 w-10 text-gray-500" />
                      </span>
            </button>

            <slot></slot>

            <div class="button-group">
              <slot name="buttons"></slot>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </HeadlessDialog>
  </TransitionRoot>
</template>

<style scoped>
.button-group {
  @apply mt-6 flex flex-row items-center justify-end gap-x-6;
}

.button--positive {
  @apply rounded-md bg-call-it-500 px-3 py-2 text-sm
  font-semibold text-white shadow-sm hover:bg-call-it-400
  focus-visible:outline-offset-2 focus-visible:outline-call-it-500;
}

.button--negative {
  @apply text-sm font-semibold leading-6 text-white;
}
</style>