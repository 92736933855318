import PaymentCreate from "@/app/views/payments/PaymentCreate.vue";

export const route = {
  path: '/payments',
  children: [{
    path: '',
    name: 'createPayment',
    component: PaymentCreate,
    meta: { redirectActive: true }
  }]
}