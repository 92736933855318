<script setup>
import {useCurrentUser} from "@/app/stores/currentUser";
import {storeToRefs} from "pinia";
import Avatar from "@/app/components/Avatar.vue";

const store = useCurrentUser()
const {user} = storeToRefs(store)

const props = defineProps({
  size: String
})

</script>

<template>
<Avatar :user="user" :size="size" />
</template>