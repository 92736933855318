<script setup>
import { defineModel, ref } from 'vue'
import { Dialog } from '@/app/components'

const props = defineProps({
    qrSlug: {
        type: Object,
        required: true
    }
})
const size = ref('large')
const model = defineModel('open')

</script>

<template>
    <Dialog v-model:open="model">
        <div>
            <img :src="qrSlug[size]" alt="key" />
        </div>
    </Dialog>
</template>
