// JsFromRoutes CacheKey f225561bc30cc3d4901d42ae70d556fe
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper, defineEndpointHelper } from '~/app/services/apiFactory'
// api/v1/blueprint_events
export default defineEndpointHelper('api/v1/blueprint_events', {
batchUpdatePositions: /* #__PURE__ */ definePathHelper('post', '/api/v1/blueprint_events/batch_update_positions'),
create: /* #__PURE__ */ definePathHelper('post', '/api/v1/blueprint_events'),
show: /* #__PURE__ */ definePathHelper('get', '/api/v1/blueprint_events/:id'),
update: /* #__PURE__ */ definePathHelper('patch', '/api/v1/blueprint_events/:id'),
destroy: /* #__PURE__ */ definePathHelper('delete', '/api/v1/blueprint_events/:id'),
})