<script setup>
import { ref, watch } from 'vue';
import { clone } from '@/app/utils';

const model = defineModel();
const blueprint = ref({});
const emits = defineEmits(['cancel']);

const cloneModel = () => {
	if (model.value) {
		blueprint.value = clone(model.value)
	}
}

watch(
	() => model,
	() => cloneModel(),
	{ immediate: true, deep: true }
)



const update = (_) => {
	model.value = blueprint.value;
}

</script>

<template>
	<form @submit.prevent="update">
		<fieldset class="fieldset">
			<legend>Detail</legend>
			<div class="field">
				<label for="name">Name</label>
				<input name="name" type="text" v-model="blueprint.name" />
			</div>

			<div class="button-group">
				<button class="btn btn--positive" type="submit">Save</button>
				<button class="btn" @click.prevents="emits('cancel')">Cancel</button>
			</div>
		</fieldset>
	</form>
</template>

<style scoped>
/* TODO - these are a later version of same styles in Session Edit - should likely be form defaults... */
.button-group {
	@apply mt-4 flex justify-end;

	.btn {
		@apply mx-0
	}

	.btn--positive {
		@apply order-1;
	}
}

.fieldset {
	+.fieldset {
		@apply mt-8
	}

	legend {
		@apply text-xl text-ci2-brand font-bold;
	}
}

.field {
	@apply block;

	+.field {
		@apply mt-4
	}

	label {
		@apply text-ci2-white font-bold tracking-wide;
	}

	input:not([type="checkbox"]) {
		@apply w-full
	}
}
</style>