import {DirectUpload} from "@rails/activestorage";
import {useApi} from "@/app/services/api";
import {createEventHook} from "@vueuse/core";

export const useUpload = () => {
  const $api = useApi()
  const error = createEventHook()
  const complete = createEventHook()

  function onChange(event) {
    upload(event.target.files, event.target.name)
  }

  function upload(fileList, name='attachment') {

    return new DirectUpload(
      fileList.item(0),
      $api.apiV1StorageUploads.create.path()
    ).create((err, blob) => {
      if (err) {
        return error.trigger({name, err})
      } else {
        return complete.trigger({name, blob})
      }
    })
  }

  const onError = error.on
  const onComplete = complete.on

  return {
    upload,
    onChange,
    onError,
    onComplete
  }
}