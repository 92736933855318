<script setup>
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';


import AdminLink from "@/app/components/admin/AdminLink.vue";
import { PencilIcon, TrashIcon } from '@heroicons/vue/24/solid';
import { useBlueprint } from '@/app/stores/blueprint';
import Page from '@/app/components/Page.vue';
import { Dialog } from "@/app/components";
import { EventUpdate, initialState } from '../../components/events/blueprint-utilities';
import EventForm from "@/app/components/events/EventForm.vue";

const store = useBlueprint();
const route = useRoute();
const router = useRouter();

const { blueprint, orderedEvents } = storeToRefs(store);

async function createSession() {
	const res = await store.generateSession(route.params.id);

	router.push(`/sessions/${res.slug}`);
}

async function loadBlueprint() {
	if (!route.params.id) { return }

	console.log('loading blueprint', route.params.id)

	const result = await store.show(route.params.id)
  	console.log('show', result)
}

watch(
	() => route.params.id,
	async () => await loadBlueprint(),
	{ immediate: true }
)

////// event manipulation logic
// a lot of this should go to the backend
const desiredNewEventPosition = ref(1);
const editEventData = ref(initialState());
const isNewEvent = ref(true);
const eventFormOpen = ref(false);

function openFormAsNew(eventPosition) {
	isNewEvent.value = true;
	desiredNewEventPosition.value = eventPosition;
	editEventData.value = {...initialState()};
	eventFormOpen.value = true;
}

function openFormAsEdit(event) {
	isNewEvent.value = false;
	editEventData.value = {...event};
	eventFormOpen.value = true;
}

async function handleEventFormSubmit() {
	if (isNewEvent.value) {
		const update = new EventUpdate(editEventData.value, blueprint.value.id, desiredNewEventPosition.value);
		console.log('creating new event, update: ', update);
		await store.createEvent({...update});
	} else {
		const update = new EventUpdate(editEventData.value, blueprint.value.id, editEventData.value.position);
		console.log(`updating event id: ${editEventData.value?.id} with update: `, update);
		await store.updateEvent({id: editEventData.value?.id, ...update});
	}

	eventFormOpen.value = false;

	await loadBlueprint(); // refresh blueprint to display new event
}

async function removeEvent(id, eventPosition) {
	console.log('removing event with id: ', id, 'at position: ', eventPosition);

  await store.destroyEvent({ id });
	await loadBlueprint();
}
</script>

<template>
	<Page>
		<template #title>
			<div class="title">
				{{ blueprint.name }}
				<admin-link id='edit_current' :to="`/blueprints/${route.params?.id}/edit`">
					<PencilIcon class="icon" />
				</admin-link>
			</div>
		</template>
		<template #actions>
			<admin-link id="collection_link" to="/blueprints" class="text-sm mt-2 ml-auto text-white">
				&lang; view all
			</admin-link>
		</template>
		<div class="bp">
			<div class="bp__info">
				<p><span>created:</span> {{ blueprint.createdAt }}</p>
				<p><span>last update:</span> {{ blueprint.updatedAt }}</p>
			</div>
			<div class="bp__events">
				<div class="outer__add">
					<button class="bp__add" @click="openFormAsNew(1)"> (+) Add Event </button>
				</div>
				<div class="outer" v-for="(event, i) in orderedEvents" :key="i + 1">
					<div class="outer__info">
						
						<div class="inner">
							<div class="inner__event">
								<div class="event">
									<span class="event__name">
										{{ event.name }}
									</span>
									<div class="event__detail">
										<span class="trueOutcome">
											<span class="label">True Label:</span>
											{{ event.trueOutcome?.label }}
										</span>
										<span class="falseOutcome">
											<span class="label">False Label:</span>
											{{ event.falseOutcome?.label }}
										</span>
										<span class="duration">
											<span class="label">Duration:</span>{{ event.duration }}
										</span>
									</div>
									<!-- <Event 
										v-bind="event"
										v-model:call="event.call"
									/> -->
								</div>
							</div>
							<div class="inner__controls">
								<button class="bp__edit" @click="openFormAsEdit(event)"><PencilIcon class="edit"/></button>
								<button class="bp__trash" :data-event-id="event.id" @click="removeEvent(event.id, event.position)"> <TrashIcon class="trash" /></button>
							</div>
						</div>

					</div>
					<div class="outer__add">
						<button class="bp__add" @click="openFormAsNew(i + 2)"> (+) Add Event </button>
					</div>
				</div>
			</div>
			<div class="bp__actions">
				<button id="create_session" @click="createSession" class="btn btn--positive">Create Session from Blueprint</button>
			</div>
		</div>
		<Dialog v-model:open="eventFormOpen">
			<EventForm
				@created="handleEventFormSubmit"
				v-model="editEventData"
			/>
		</Dialog>
	</Page>
</template>

<style scoped>

.title {
	@apply
		flex items-center gap-4
}

.icon {
	@apply w-5 h-5 fill-white
}

.bp {
	@apply
		flex flex-col
		py-4
}

.bp__info {
	@apply
		my-4
	;
	span {
		@apply
			text-ci2-grey2
	}
}

.bp__events {
	@apply
		grow
		my-4
}

.bp__actions {
	@apply
		mt-4
}


.outer {
	@apply
		mt-2;
}

.outer__add {
	@apply
		text-center
		mt-8
		border-t
		border-t-ci2-grey2
		border-dotted
	;

	button {
		@apply
			bg-ci2-black
			transform translate-y-[-0.9em]
			px-2
			hover:text-ci2-brand
	}
}

.inner {
	@apply
		flex
		justify-between
	;
}

.inner__controls {
	@apply
		flex
		justify-between
	;

	.trash, .edit {
		@apply
			w-5
			ml-5
	}

	.trash {
		@apply
			fill-utility-error
	}
}

/* unsure if this will replace, augment or be an alternative to the <Event> component */
.event {}

.event__name {
	@apply
		block
		text-2xl
}

.event__detail {
	@apply
		flex
		gap-6
	;
	
	.label {
		@apply
			text-ci2-grey2
			text-sm
			mr-2
	}
}
</style>