import * as live from './live'
import {useSessions} from "@/app/stores/session";
import {useCurrentUser} from "@/app/stores/currentUser";
import { BoltIcon, ChatBubbleLeftRightIcon, TrophyIcon, ListBulletIcon, Cog6ToothIcon} from "@heroicons/vue/24/solid";

const requiresRegistration = ({registrationType}) => {
  return registrationType === 'closed_registration'
}

const checkRegistrationType = async (to, from) => {
  const sessions = useSessions(),
        current_user = useCurrentUser(),
        slug = to.params.slug

  let session = await sessions.findBySlug(slug)
  console.log('FIND BY SLUG', slug, session)
  // if (requiresRegistration(session)) {
  //   console.log('requires registration')
  // }
}

export const route = {
  path: '/sessions',
  // component: Layout,
  children: [
    // Public
    {
      path: '',
      name: 'sessions',
      component: () => import('./SessionIndex.vue'),
      meta: {
        redirectActive: true,
        guestAccessible: true
      }
    },
    // {
    //   path: 'blueprint',
    //   name: 'session_blueprint',
    //   component: SessionBlueprint,

    // },

    {
      path: ':slug',
      name: 'session',
      component: () => import('./SessionShow.vue'),
      // beforeEnter: checkSessionRegistrationType,
      children: [
        {
          path: 'join',
          name: 'sessionRegistration',
          component: live.Registration,
          meta: { redirectActive: true }
        },
        {
          path: '',
          name: 'action',
          meta: {
            guestAccessible: true
          },
          component: live.Action
        },
        {
          path: 'leaders',
          name: 'leaders',
          component: live.Leaders,
        },
        {
          path: 'chat',
          name: 'chat',
          passProps: true,
          component: live.Chat,
          meta: { redirectActive: true }
        },
        {
          path: 'history',
          name: 'history',
          component: live.CallHistory
        },
        {
          path: 'admin',
          name: 'admin',
          component: live.Admin,
          meta: { requiresAuth: true }
        },
      ]
    },

    // Admin
    {
      path: ':id/edit',
      name: 'edit',
      component: () => import('./SessionEdit.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: 'new',
      name: 'create',
      component: () => import('./SessionCreate.vue'),
      meta: { requiresAuth: true }
    },
  ]
}

export const navigation = [
  {
    icon: Cog6ToothIcon,
    to: {name: 'admin', params: route.params},
    label: 'Admin',
    meta: { admin: true}
  },
  {
    icon: BoltIcon,
    to: {name: 'action', params: route.params},
    label: 'Action',
  }, 
  {
    icon: TrophyIcon,
    to: {name: 'leaders', params: route.params},
    label: 'Leaders',
    meta: { admin: true }
  },
  {
    icon: ChatBubbleLeftRightIcon,
    to: {name: 'chat', params: route.params},
    label: 'Chat',
    meta: { admin: true }
  },
  {
    icon: ListBulletIcon,
    to: {name: 'history', params: route.params},
    label: 'History'
  }
]