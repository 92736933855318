<script setup>
import Event from "@/app/components/events/Event.vue";
import { useCurrentSession } from "../store";
import { storeToRefs } from "pinia";

const { eventHistory, isLoading } = storeToRefs(useCurrentSession())

</script>

<template>
  <div>
  <template v-if="!isLoading && eventHistory">
    <div class="w-full max-w-7xl">
      <ul class="flex flex-col gap-8 relative">
        <Event
            v-for="(event, i) in eventHistory" :key="event.id"
            v-bind="event"
            v-model:call="event.call"
        />
      </ul>
    </div>
  </template>
  </div>
</template>

<style scoped>

</style>