import { defineStore } from 'pinia';
import { useChannel } from "@/app/composable/useChannel";


export const defineChannelStore = (name, channelId) => {
  return defineStore(name, () => {
    // channel interface
    const channel = useChannel()

    function subscribe(id) {
      channel.subscribe({ ...channelId, id })
    }

    function unsubscribe() {
      channel.unsubscribe()
    }

    return {
      subscribe,
      unsubscribe,
      emit: channel.emit,
      all: channel.all,
      off: channel.off,
      on: channel.on
    }
  })
}

export const sessionChannelStore = defineChannelStore('sessionChannel', { channel: 'SessionsChannel' })
export const userChannelStore = defineChannelStore('userChannel', { channel: 'UserChannel' })
