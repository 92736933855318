import {
  addCustomTab, addCustomCommand, onDevToolsConnected, onDevToolsClientConnected,
  setupDevtoolsPlugin
} from "@vue/devtools-api";
import { createEventHook } from "@vueuse/core/index.cjs";
import {inject} from "vue";

export const useDevtools = () => {
  return inject('$CIDevTools')
}

class CallItDevtools {

  pluginLabel = 'ci'
  pluginId = 'org.callit'
  layerId = 'org.callit.events'
  inspectorId = 'org.callit.inspector'


  static setup(app) {
    console.log('devtools', app)
    setupDevtoolsPlugin({
      id: this.pluginId,
      label: this.pluginLabel,
      packageName: this.pluginLabel,
      enableEarlyProxy: true,
      app
    }, (api) => {
      const devtools = new this({api, app})
      console.log('on devtools setup', devtools)
    })
  }

  constructor({api, app}) {
    this.api = api
    this.app = app
    this.register()
    app.config.globalProperties.$CIDevTools = this
    app.provide('$CIDevTools', this)
  }

  now() {
    return this.api.now()
  }

  /**
   * Emits an event with the given label, data, meta, and groupId.
   *
   * @param {string} [title='event'] - The label of the event.
   * @param {object} [data={}] - The data object associated with the event.
   * @param {object} [meta={}] - The meta object associated with the event.
   * @param {string|null} [groupId=null] - The groupId for grouping events.
   *
   * @return {void}
   */
  emit(title = 'event', {data = {}, meta = {}, groupId = null}) {
    this.api.addTimelineEvent({
      layerId: this.layerId,
      event: {
        time: this.now(),
        data,
        meta,
        groupId,
        title,
      }
    })
  }

  register() {
    this.api.addInspector({
      id: this.inspectorId,
      color: 0x3F6C61FF,
      label: 'Call-It'
    })

    this.api.addTimelineLayer({
      id: this.layerId,
      color: 0x3F6C61FF,
      label: 'Call-It '
    })

    // timeline
    // this.api.on.inspectTimelineEvent(this.inspectTimelineEvent.bind(this));
    this.api.on.timelineCleared(this.timelineCleared.bind(this));
    // inspector
    this.api.on.getInspectorTree(this.getInspectorTree.bind(this));
    this.api.on.getInspectorState(this.getInspectorState.bind(this));
    this.api.on.editInspectorState(this.editInspectorState.bind(this));
    // component inspector
    this.api.on.inspectComponent(this.inspectComponent.bind(this));
    this.api.on.visitComponentTree(this.visitComponentTree.bind(this));
    this.api.on.editComponentState(this.editComponentState.bind(this));
  }

  inspectTimelineEvent(payload) {
    console.log('inspectTimelineEvent', payload)
    if (payload.layerId === this.layerId) {
      console.log('inspectTimelineEvent', payload)
      return new Promise((resolve) => {
        payload.data = {
          time: this.now(),
          ...payload.data
        };
        resolve();
      });
    }
  }

  timelineCleared() {
    console.log('timelineCleared')
  }

  getInspectorTree(payload) {
    if (payload.inspectorId === this.inspectorId) {
      console.log('getInspectorTree', payload)
      payload.rootNodes = [
        {
          id: 'root1',
          label: 'Root 1',
          children: [
            {}
          ]
        },
        {
          id: 'root2',
          label: 'Root 2',
          children: [
            {}
          ]
        }
        // More root nodes...
      ]
    }
  }

  getInspectorState(payload) {
    if (payload.inspectorId === this.inspectorId) {
      console.log(payload)

      if (payload.nodeId === 'root1') {
        payload.state = {
          'root1': [
            {key: 'root.1.1', value: 'Root 1.2'},
            {key: 'root.1.2', value: 'Root 1.2'}
          ]
        }
      }

      if (payload.nodeId === 'root2') {
        payload.state = {
          'root2': [
            {key: 'root.2.1', value: 'Root 2.2'},
            {key: 'root.2.2', value: 'Root 2.2'}
          ]
        }
      }
    }
  }

  editInspectorState(payload) {
    // body of editInspectorState event handler goes here
  }

  inspectComponent(payload) {
    // body of inspectComponent event handler goes here
  }

  visitComponentTree(payload) {
    // body of visitComponentTree event handler goes here
  }

  editComponentState(payload) {
    // body of editComponentState event handler goes here
  }
}


class dt {

  static descriptor = {
    pluginLabel: 'ci',
    pluginId: 'org.callit',
    layerId: 'org.callit.events',
    inspectorId: 'org.callit.inspector',
    enableEarlyProxy: true,
    // componentStateTypes: [
    //
    // ]
  }

  constructor(app) {
    this.app = app
    this.api = null
  }

  setup(api) {
    this.api = api

    console.log(this.api, this.api.on)

    const handler = (payload) => {


    }

    //notifyComponentDidUpdate
    //adddInspector
    //sendInspectorTree
    //sendInspectorState
    //selectInspectorNode
    // now
    // addTimelineLayer
    // addTimelineEvent
    // getSettings
    // getComponentInstances
    // getComponentBounds
    // getComponentName
    // highlightEvent
    // unhighlightEvent

    [
      //component inspector
      'visitComponentTree',
      'inspectComponent',
      'editComponentState',
      // inspector
      'getInspectorTree',
      'getInspectorState',
      'editInspectorState',
      // timeline
      'inspectTimelineEvent',
      'timelineCleared',
      //settings
      'setPluginSettings',
    ]

    this.api.on
  }
}


export const plugin = (app) => {

  const cidt = new dt(app)

  setupDevtoolsPlugin(dt.descriptor, cidt.setup)
}
